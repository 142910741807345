<template>
    <div>
        <el-dialog :visible.sync="dialogVal" width="20%" center :show-close="false">
            <span slot="title" class="title">
                <span class="title-txt">选择链接</span>
                <img class="close-icon" src="@/assets/images/icon/close-black.png" @click="cancel" />
            </span>
            <div class="tree">
                <el-tree
                    class="filter-tree"
                    :data="treeData"
                    :props="defaultProps" 
                    @check="checkChange" 
                    node-key="id" 
                    accordion
                    check-strictly
                    highlight-current
                    show-checkbox 
                    :default-expanded-keys="expanded"
                    :filter-node-method="filterNode"
                    ref="tree">
                </el-tree>

            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import ArrayUtils from '@/utils/arrayTree.js'
export default {
    props: {
        dialogVal: {
            type: Boolean,
            default: false,
        },
        expanded:{
            // 默认展开
            type: Array,
            default:()=>{
               return [1]
            }
        },
    },
    mounted(){
    	//获取到treeData后 此处已省略请求数据过程，请自行添加  处理只能选择最后一个节点
        ArrayUtils.filterTreeData(this.treeData);
    },
    watch:{
        /** 监听检索字段变化 */
        filterText(val) {
            this.$refs.tree.filter(val);
        },
    },
    data() {
        return {
            filterText: '',//检索字段
            defaultProps: {
                children: 'children',
                label: 'label'
            },//过滤使用字段
            treeData:  [
                {
                    label: "一级 1",
                    id:'1',
                    children: [
                        {
                            label: "二级 1-1",
                            children: [
                                {   
                                    id:'6',
                                    label: "三级 1-1-1",
                                },
                            ],
                        },
                    ],
                },
                {
                    label: "一级 2",
                    id:'2',
                    children: [
                        {
                            label: "二级 2-1",
                            children: [
                                {
                                    label: "三级 2-1-1",
                                },
                            ],
                        },
                        {
                            label: "二级 2-2",
                            children: [
                                {
                                    label: "三级 2-2-1",
                                },
                            ],
                        },
                    ],
                },
                {
                    label: "一级 3",
                    id:'3',
                    children: [
                        {
                            label: "二级 3-1",
                            children: [
                                {
                                    label: "三级 3-1-1",
                                },
                            ],
                        },
                        {
                            label: "二级 3-2",
                            children: [
                                {
                                    label: "三级 3-2-1",
                                },
                            ],
                        },
                    ],
                },
            ],//资产大类树形结构数据
            checkedData: {},//当前点击的数据
        };
    },
    methods: {
        confirm() {
            const that = this;
            this.$emit("dialogChange", 1, that.checkedData);
        },
        cancel() {
            this.$emit("dialogChange", 2);
        },
        /* 控制树形单选 */
        checkChange(data, checked) {
            if (checked) {
                if (!!data.children && data.children.length>0) {
                    console.log("有子节点不可选")
                }else{
                    this.checkedData = data;
                    this.$refs.tree.setCheckedKeys([data.id]);
                }
            }
            // console.log("checked data",data,this.checkedData)
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    .title-txt {
        float: left;
        font-size: 20px;
        font-weight: 600;
    }
    .close-icon {
        float: right;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}

.tree {
    max-height: 300px;
    overflow-y: auto;
}

::v-deep .el-dialog {
    border-radius: 16px;
}

::v-deep .el-dialog__wrapper {
    display: flex;
    align-items: center;
}
</style>
